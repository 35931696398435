import { SfxState } from '@/contexts/SfxContext';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface SettingsState {
  isAudioEnabled: boolean | null;
  sfx: SfxState | null;
  isAccountMenuOpen: boolean;
  isSettingsMenuOpen: boolean;
  isWalletDialogOpen: boolean;
  openAccountMenu: () => void;
  closeAccountMenu: () => void;
  openSettingsMenu: () => void;
  closeSettingsMenu: () => void;
  openWalletDialog: () => void;
  closeWalletDialog: () => void;
  setSfx: (sfx: SfxState) => void;
  setAudioEnabled: (isAudioEnabled: boolean) => Promise<void>;
  toggleAudioEnabled: () => Promise<void>;
}

export const useSettingsStore = create<SettingsState>()(
  persist(
    (set, get) => ({
      isAudioEnabled: null,
      sfx: null,
      isAccountMenuOpen: false,
      isSettingsMenuOpen: false,
      isWalletDialogOpen: false,

      openAccountMenu: () => {
        set({ isAccountMenuOpen: true, isSettingsMenuOpen: false });
      },

      closeAccountMenu: () => {
        set({ isAccountMenuOpen: false });
      },

      openSettingsMenu: () => {
        set({ isSettingsMenuOpen: true, isAccountMenuOpen: false });
      },

      closeSettingsMenu: () => {
        set({ isSettingsMenuOpen: false });
      },

      openWalletDialog: () => {
        set({ isWalletDialogOpen: true });
      },

      closeWalletDialog: () => {
        set({ isWalletDialogOpen: false });
      },

      setSfx: (sfx: SfxState) => {
        set({ sfx })
      },

      toggleAudioEnabled: async () => {
        const currentAudioState = get().isAudioEnabled;
        await get().setAudioEnabled(!currentAudioState);
      },

      setAudioEnabled: async (isAudioEnabled: boolean) => {
        try {
          const response = await fetch('/api/settings/audio', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ audioOn: isAudioEnabled }),
          });
          if (!response.ok) throw new Error('Failed to update audio setting');
          set({ isAudioEnabled });
        } catch (error) {
          console.error('Error updating audio setting:', error);
        }
      },

    }),
    {
      name: 'settings-storage',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
